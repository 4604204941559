const questions = [
  {
    questionText: "Какой же Новый год без елочки! Чем ее украсишь?",
    answerOptions: [
      {
        answerText: "Достану с антресолей старые игрушки",
        answerResult:
          "Каждый цветной шарик, каждый розовый пряник и золотая шишка — все это твои детские воспоминания и история семьи.",
        category: "cat1",
      },
      {
        answerText: "В новый год — с новыми игрушками",
        answerResult:
          "Праздник для тебя начинается задолго до 31 декабря, среди витрин с шарами и гирляндами.",
        category: "cat2",
      },
      {
        answerText: "Чем больше огоньков, тем лучше",
        answerResult:
          "Твоя елка — настоящий маяк, освещающий дорогу к подаркам.",
        category: "cat4",
      },
      {
        answerText: "Не ставлю елку",
        answerResult:
          "Тебе незачем ставить новую елку, если еще не убрал прошлогоднюю.",
        category: "cat3",
      },
    ],
    image: "images/01.webp",
  },

  {
    questionText: "Какие подарки приготовишь?",
    answerOptions: [
      {
        answerText: "Сделанные своими руками",
        answerResult:
          "В детстве твоими лучшими друзьями были ножницы, бумага и клей. Время прошло, но тяга к творчеству осталась.",
        category: "cat4",
      },
      {
        answerText: "Стараюсь исполнять мечты",
        answerResult:
          "Весь год ты внимательно слушаешь своих близких, чтобы в декабре точно знать, о чем они мечтают, и услышать от них: «Это именно то, что я хотел!»",
        category: "cat2",
      },
      {
        answerText: "Передариваю подарки, которые не пригодились",
        answerResult:
          "В этом деле главное — запомнить, кто именно подарил тебе аромалампу или чесалку для спины.",
        category: "cat3",
      },
      {
        answerText: "Обязательно дарю символ года",
        answerResult:
          "Игрушечный зайчик, свинья-копилка, чугунная статуя быка и даже живой щенок. Восточный гороскоп всегда подскажет идею для подарка.",
        category: "cat1",
      },
    ],
    image: "images/02.webp",
  },

  {
    questionText: "В твою дверь стучат. Это гости! Расскажи о них.",
    answerOptions: [
      {
        answerText: "Какие гости?",
        answerResult:
          "В такую погоду свои дома сидят, телевизор смотрют. Только чужие шастают. Не будем дверь открывать!",
        category: "cat3",
      },
      {
        answerText: "Родные и близкие",
        answerResult:
          "Видитесь вы каждый день или встречаетесь раз в год, — это те люди, с которыми всегда легко. Твоя семья.",
        category: "cat1",
      },
      {
        answerText: "Жду всех, у кого новогоднее настроение",
        answerResult:
          "Твои двери всегда открыты для искренних улыбок, вспышек бенгальских огней и грохота хлопушек.",
        category: "cat2",
      },
      {
        answerText: "Участковый",
        answerResult:
          "Здравствуйте, граждане. Почему шумим? Нехорошо… без меня.",
        category: "cat4",
      },
    ],
    image: "images/03.webp",
  },

  {
    questionText: "У тебя есть праздничный наряд?",
    answerOptions: [
      {
        answerText: "Я всегда следую современным трендам",
        answerResult:
          "Как Новый год встретишь, так его и проведешь. А кому бы не хотелось круглый год выглядеть как с обложки глянца?",
        category: "cat4",
      },
      {
        answerText: "Костюм Деда Мороза или Снегурочки",
        answerResult:
          "Их ждут все дети и многие взрослые. Так почему бы не принести людям немного радости, просто надев ватную бороду или кокошник?",
        category: "cat2",
      },
      {
        answerText: "У меня есть дурацкий новогодний свитер",
        answerResult:
          "Пестрый свитер с оленями ждал своего часа целый год. Надевай его скорее!",
        category: "cat1",
      },
      {
        answerText: "У меня каждый день как праздник",
        answerResult:
          "У кого как, а у тебя 31 декабря — рабочий день. Так что и встречать его вполне нормально в повседневной одежде.",
        category: "cat3",
      },
    ],
    image: "images/04.webp",
  },

  {
    questionText: "Что поставишь на праздничный стол?",
    answerOptions: [
      {
        answerText: "Оливье, заливное, мясо по-французски",
        answerResult:
          "Семейные узы станут еще крепче, если заправить их майонезом",
        category: "cat1",
      },

      {
        answerText: "Смотря чем угостят",
        answerResult:
          "У кого-то хорошо получается готовить, а у кого-то — есть. Каждый должен заниматься своим делом.",
        category: "cat3",
      },
      {
        answerText: "Заказываю еду в службе доставки",
        answerResult:
          "Когда каждая минута на счету, проще взять телефон и заказать доставку.",
        category: "cat2",
      },
      {
        answerText: "Побольше сладостей",
        answerResult:
          "Ты знаешь, что такое сладкая жизнь. А сбросить набранные килограммы помогут танцы до утра.",
        category: "cat4",
      },
    ],
    image: "images/05.webp",
  },

  {
    questionText: "Что самое сложное?",
    answerOptions: [
      {
        answerText: "Не уснуть до полуночи",
        answerResult:
          "Пять минут, пять минут — это много или мало? В самый раз, чтобы вздремнуть!",
        category: "cat4",
      },
      {
        answerText: "Искренне всех поздравить",
        answerResult:
          "Подобрать нужные слова бывает непросто. Поэтому нужно прислушаться к себе и сказать то, что подсказывает сердце.",
        category: "cat3",
      },
      {
        answerText: "Упаковать все подарки",
        answerResult:
          "Тебе потребуется упаковочная бумага, ленточки, скотч и много-много терпения.",
        category: "cat1",
      },
      {
        answerText: "Распутать гирлянду",
        answerResult:
          "Прежде чем рука сама потянется за ножницами, подумай: может быть, светящийся клубок, включенный в розетку, это не так уж и плохо?",
        category: "cat2",
      },
    ],
    image: "images/06.webp",
  },

  {
    questionText: "Что посмотришь?",
    answerOptions: [
      {
        answerText: "«Золушка», «Ирония судьбы…», «Карнавальная ночь»",
        answerResult:
          "Любимые фильмы, без которых ты уже не представляешь подготовку к празднику.",
        category: "cat1",
      },
      {
        answerText: "Телевизор — для скучных! Айда на горку!",
        answerResult: "Почувствуй себя чемпионом олимпиады по санному спорту.",
        category: "cat2",
      },
      {
        answerText: "Дискотека 80-х, дискотека 90-х, дискотека нулевых",
        answerResult: "Танцевальный марафон объявляется открытым!",
        category: "cat4",
      },
      {
        answerText: "Итоговый выпуск новостей",
        answerResult:
          "Потому что важно подвести итоги, прежде чем начать что-то новое.",
        category: "cat3",
      },
    ],
    image: "images/07.webp",
  },

  {
    questionText: "Чего пожелаешь?",
    answerOptions: [
      {
        answerText: "Счастья, здоровья",
        answerResult: "А остальное приложится. Ну и денег побольше.",
        category: "cat1",
      },
      {
        answerText: "Бесконечный кусок пиццы",
        answerResult:
          "Да, это несбыточное желание, но ведь мечта и должна быть прекрасной и недосягаемой.",
        category: "cat2",
      },
      {
        answerText: "Чтоб сбывались все мечты",
        answerResult: "Или хотя бы некоторые, самые заветные.",
        category: "cat4",
      },
      {
        answerText: "Чтобы новогодняя ночь быстрее прошла",
        answerResult:
          "Чтобы стихли грохот фейерверков и бой курантов, чтобы отзвучали все песни и можно было наконец-то выспаться.",
        category: "cat3",
      },
    ],
    image: "images/08.webp",
  },
];

export default questions;
