/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Title from "../Title/Title";
import Button from "../Button/Button";
import Paragraph from "../Paragraph/Paragraph";
import styles from "./Result.module.scss";
import classNames from "classnames";
import { RWebShare } from "react-web-share";
import {
  VKShareButton,
  OKShareButton,
  ViberShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";
import {
  VKIcon,
  OKIcon,
  ViberIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";

function Result({ title, text, imageSrc }) {
  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const SiteURL = "https://newyear.taximaximapp.ru";
  const ImageURL = SiteURL + imageSrc;

  return (
    <>
      <div className={styles.posterContainer}>
        <img src={imageSrc} className={styles.image} alt="" />
      </div>
      <div className={styles.resultContainer}>
        <div
          className={classNames({
            [styles.resultContainerBox]: true,
            [styles.onScrollOption]: offset,
          })}
        >
          <Title value={title} />
          <Paragraph value={text} />
          <p className={styles.parBox}>
            Думаешь на этом все? А ведь у сервиса заказа такси «Максим» есть для
            тебя сюрприз. Открой в меню{" "}
            <a
              href="https://taximaxim.onelink.me/KKXl/newyear"
              className={styles.link}
              target="_blank"
              rel="noreferrer"
            >
              приложения
            </a>{" "}
            раздел «Предложения партнеров» и выбирай подходящие скидки и акции.
          </p>
          <RWebShare
            data={{
              title: "Я знаю, каким будет мой Новый год! А ты?",
              text: "Я знаю, каким будет мой Новый год! А ты?",
              url: SiteURL,
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <Button
              value="Поделиться результатом"
              className={styles.buttonShare}
            />
          </RWebShare>

          <div className={styles.socialBox}>
            <Paragraph value="Поделиться результатом" />
            <div className={styles.social}>
              <VKShareButton
                url={SiteURL}
                title="Я знаю, каким будет мой Новый год! А ты?"
                image={ImageURL}
                hashtag="#Maxim"
              >
                <VKIcon size={48} round={true} className={styles.icon} />
              </VKShareButton>

              <OKShareButton
                url={SiteURL}
                title="Я знаю, каким будет мой Новый год! А ты?"
                image={ImageURL}
                description={text}
              >
                <OKIcon size={48} round={true} className={styles.icon} />
              </OKShareButton>

              <ViberShareButton
                url={SiteURL}
                title="Я знаю, каким будет мой Новый год! А ты?"
                image={ImageURL}
              >
                <ViberIcon size={48} round={true} className={styles.icon} />
              </ViberShareButton>

              <WhatsappShareButton
                url={SiteURL}
                title="Я знаю, каким будет мой Новый год! А ты?"
                image={ImageURL}
              >
                <WhatsappIcon size={48} round={true} className={styles.icon} />
              </WhatsappShareButton>

              <TelegramShareButton
                url={SiteURL}
                title="Я знаю, каким будет мой Новый год! А ты?"
                image={ImageURL}
              >
                <TelegramIcon size={48} round={true} className={styles.icon} />
              </TelegramShareButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Result;
